import React, { useState } from "react";
import Header from "../../Components/header";
import NoteList from "../../Components/notelist";
import FullScreenDialog from "../../Components/Dialog";
import { useAuth } from "../../Contexts/AuthContext";
//Material
import { Alert } from "@material-ui/lab";
import { Paper } from "@material-ui/core";

export default function Dashboard() {
  const { currentUser } = useAuth();

  const [openDialog, setOpenDialog] = useState(false);
  const newNote = () => {
    console.log("new note");
    setOpenDialog(true);
  };

  return (
    <Paper style={{ height: "100vh" }}>
      {!currentUser.emailVerified && (
        <Alert variant="filled" severity="warning">
          Verify the email address for use the App!
        </Alert>
      )}
      <Header newNote={newNote} />
      <NoteList></NoteList>
      <FullScreenDialog
        id={null}
        open={openDialog}
        setOpen={setOpenDialog}
      ></FullScreenDialog>
    </Paper>
  );
}
