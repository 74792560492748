import React from "react";

const Title = ({ first, second }) => {
  const boldstyle = {
    fontWeight: "bold",
  };

  return (
    <div className="title">
      <span className="first">{first}</span>
      <span style={boldstyle} className="second">
        {second}
      </span>
    </div>
  );
};

export default Title;
