import React, { useState, useEffect } from "react";
import { ContentState, EditorState } from "draft-js";

import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//material
import { Delete, Lock, LockOpen } from "@material-ui/icons";

//Dialog
import FullScreenDialog from "./Dialog";
//context
import { db } from "../firebase_config";
import { useAuth } from "../Contexts/AuthContext";

export default function Note({ note }) {
  const [openNote, setOpenNote] = useState(false);
  const [editorState, setEditorState] = useState(getEditorState(note.body));

  const { currentUser } = useAuth();

  useEffect(() => {
    setEditorState(getEditorState(note.body));
  }, [note]);

  function getEditorState(body) {
    let eState;
    const contentBlock = htmlToDraft(body);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      eState = EditorState.createWithContent(contentState);
    }
    return eState;
  }

  function handleClickOpen() {
    console.log("opened");
    setOpenNote(true);
  }

  async function deleteNote() {
    await db
      .collection("utenti")
      .doc(currentUser.email)
      .collection("note")
      .doc(note.id)
      .delete();
    console.log("note deleted", note.id);
  }

  const lockIcon = note.locked ? (
    <Lock className="icon" />
  ) : (
    <LockOpen className="icon" />
  );

  return (
    <>
      <div className="note">
        <div className="note-content" onClick={handleClickOpen}>
          <h2>{note.title}</h2>
          <Editor
            toolbarHidden="true"
            editorState={editorState}
            readOnly="true"
          ></Editor>

          <div className="locked">{lockIcon}</div>
          <div
            className="color"
            style={{
              backgroundColor: note.color !== "#000" ? note.color : "unset",
            }}
          />
        </div>
        <div className="note-actions">
          <div className="delete-button" onClick={deleteNote}>
            <Delete className="icon"></Delete>
          </div>
        </div>
      </div>

      <FullScreenDialog
        id={note.id}
        open={openNote}
        setOpen={setOpenNote}
      ></FullScreenDialog>
    </>
  );
}
