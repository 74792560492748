import React, { useEffect, useState } from "react";
import "./ColorPicker.css";
import { db } from "../../firebase_config";
import { CircularProgress } from "@material-ui/core";

export default function ColorPicker({ color, onChange }) {
  const [loading, setLoading] = useState(true);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    fetchColors();
  }, []);

  async function fetchColors() {
    setLoading(true);
    console.log("Fetching colors...");
    db.collection("settings")
      .doc("colors")
      .onSnapshot(
        (snapshot) => {
          if (snapshot.data()) {
            console.log(snapshot.data()["colors"]);
            setColors(snapshot.data()["colors"]);
            setLoading(false);
          }
        },
        (error) => {
          console.log(error.message);
        }
      );
  }

  return (
    <div className="color-picker">
      {!loading ? (
        colors.map((value) => (
          <div
            onClick={(_) => onChange(value["value"])}
            key={`color-${value["name"]}`}
            className={`color ${color === value["value"] ? "selected" : ""}`}
          >
            <div className="color-container">
              <div
                className={`bubble`}
                style={{ backgroundColor: value["value"] }}
              ></div>
            </div>
            <p className="color-title">{value["name"]}</p>
          </div>
        ))
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
