import React from "react";
import { RichUtils } from "draft-js";
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

export default function Block({ editorState, onEditorStateChange }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleMenuItemClick = (event, index) => {
    event.preventDefault();

    onEditorStateChange(
      RichUtils.toggleBlockType(editorState, BlockStyle[index].value)
    );

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function getBlockStyle() {
    const index = BlockStyle.findIndex((element) => {
      return (
        editorState
          .getCurrentContent()
          .getBlockForKey(editorState.getSelection().getStartKey())
          .getType() === element.value
      );
    });

    return index === -1 ? 0 : index;
  }

  const BlockStyle = [
    { label: "p", hint: "Paragrafo", value: "div" },
    { label: "H1", hint: "Titolo 1", value: "header-one" },
    { label: "H2", hint: "Titolo 2", value: "header-two" },
    { label: "H3", hint: "Titolo 3", value: "header-three" },
    { label: "H4", hint: "Titolo 4", value: "header-four" },
    { label: "H5", hint: "Titolo 5", value: "header-five" },
    { label: "H6", hint: "Titolo 6", value: "header-six" },
  ];

  return (
    <div style={{ zIndex: "1000" }}>
      <ButtonGroup
        style={{ marginRight: "5px" }}
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleToggle}>
          {BlockStyle[getBlockStyle()].label}
        </Button>
        <Button
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        style={{ zIndex: "1000" }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {BlockStyle.map((option, index) => (
                    <MenuItem
                      key={option.label}
                      selected={index === getBlockStyle()}
                      onMouseUp={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.hint}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
