/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import Note from "./Note";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { db } from "../firebase_config";
import { useAuth } from "../Contexts/AuthContext";
import { Paper } from "@material-ui/core";

const NoteList = () => {
  const [notes, setNotes] = useState([]);

  const { currentUser } = useAuth();

  useEffect(() => {
    fetch();
  }, []);

  function fetch() {
    console.log("Fetching notes..");

    db.collection("utenti")
      .doc(currentUser.email)
      .collection("note")
      .onSnapshot(function (querySnapshot) {
        console.log("notes modified");
        setNotes(
          querySnapshot.docs.map((note) => ({
            id: note.id,
            title: note.data().title,
            body: note.data().body,
            locked: note.data().locked,
            color: note.data().color,
          }))
        );
      });
  }

  return (
    <Paper elevation={0}>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3, 1024: 4 }}
      >
        <Masonry>
          {notes.map((note) => (
            <Note key={note.id} note={note}></Note>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </Paper>
  );
};

export default NoteList;
