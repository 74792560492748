import React, { useState } from "react";
import ColorPicker from "./ColorPicker/ColorPicker";
import { Button, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import { ColorLens, Close } from "@material-ui/icons";
import { useAuth } from "../Contexts/AuthContext";

export function ConfirmExitDialogButton({ edited, setDialogOpen }) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    if (edited) setOpen(true);
    else setDialogOpen(false);
  }

  return (
    <>
      <IconButton aria-label="exit-button" onClick={handleClose}>
        <Close className="icon"></Close>
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-exit"
      >
        <DialogTitle id="confirm-exit-title">
          Sicuro di voler uscire
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-exit-description">
            Uscendo perderai i tuoi dati.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Esci
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function ChooseColorNote({ note, setNote }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <IconButton aria-label="choose-color" onClick={() => setOpen(true)}>
        <ColorLens className="icon"></ColorLens>
      </IconButton>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="change-note-color"
      >
        <div>
          <DialogTitle id="change-note-color">Cambia colore nota</DialogTitle>
          <DialogContent>
            <ColorPicker
              color={note.color}
              onChange={(color) => {
                setNote({ ...note, color: color });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} color="primary">
              Chiudi
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export function ChangeEmailDialog({ children, ...props }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const { updateEmail } = useAuth();

  async function onDone() {
    try {
      setError("");
      await updateEmail(value);
      setOpen(false);
    } catch (e) {
      console.log(e);
      switch (e.code) {
        case "auth/email-already-in-use":
          setError("L'email inserita è già utilizzata da un altro account");
          break;
        case "aauth/invalid-email":
          setError("L'email inserita non è valida");
          break;
        case "auth/requires-recent-login":
          setError(
            "Questa operazione richiede che sia stato effettuato un login recentemente, riaccedi per poter modificare il proprio indirizzo email"
          );
          break;

        default:
          setError(error.message);
          break;
      }
    }
  }

  return (
    <>
      <Button {...props} onClick={() => setOpen(true)}>
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="change-email-title"
      >
        <DialogTitle id="change-email-title">Inserisci nuova email</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <DialogContentText>
            Ricorda che inserendo una nuova mail e cliccando conferma, dovrai
            confermare il passaggio aprendo il link che ti verrà inviato sulla
            nuova email
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            value={value}
            onChange={(v) => setValue(v.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Annulla
          </Button>
          <Button onClick={onDone} color="primary">
            Cambia email
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export function ChangePasswordModal({ children, ...props }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState({ password: "", confirmPassword: "" });
  const [error, setError] = useState("");

  const { updatePassword } = useAuth();

  async function onDone() {
    try {
      setError("");
      await updatePassword(value.password);
      setOpen(false);
    } catch (e) {
      console.log(e);
      switch (e.code) {
        case "auth/email-already-in-use":
          setError("L'email inserita è già utilizzata da un altro account");
          break;
        case "aauth/invalid-email":
          setError("L'email inserita non è valida");
          break;
        case "auth/requires-recent-login":
          setError(
            "Questa operazione richiede che sia stato effettuato un login recentemente, riaccedi per poter modificare la propria password"
          );
          break;

        default:
          setError(error.message);
          break;
      }
    }
  }

  return (
    <>
      <Button {...props} onClick={() => setOpen(true)}>
        {children}
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="change-password-title"
      >
        <form></form>
        <DialogTitle id="change-password-title">
          Inserisci nuova password e confermala
        </DialogTitle>
        <DialogContent>
          {error && <Alert severity="error">{error}</Alert>}
          <DialogContentText>
            Inserisci una nuova password e confermala per poterla modificare.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            value={value.password}
            onChange={(v) => setValue({ ...value, password: v.target.value })}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="confirmPassword"
            label="Conferma Password"
            type="password"
            value={value.confirmPassword}
            onChange={(v) =>
              setValue({ ...value, confirmPassword: v.target.value })
            }
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Annulla
          </Button>
          <Button autoFocus onClick={onDone} color="primary">
            Cambia password
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
