import "./Register.css";
import Title from "../../Components/title";
import { useAuth } from "../../Contexts/AuthContext";
import React, { useState } from "react";
import { FormControl, TextField, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Link } from "react-router-dom";

export default function Register() {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    passwordConfirmation: "",
  });
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const [loading, setLoading] = useState(false);

  const { signup } = useAuth();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (formValues.password !== formValues.passwordConfirmation) {
      return setError("Le password devono coincidere");
    }
    if (
      formValues.email === "" ||
      formValues.password === "" ||
      formValues.passwordConfirmation === ""
    ) {
      return setError("Nessun campo può essere vuoto");
    }
    try {
      setError("");
      setInfo("");
      setLoading(true);
      console.log("trying with: ", formValues.email, formValues.password);
      await signup(formValues.email, formValues.password);
      setInfo("Account correttamente registrato, verifica la tua email!");
      setLoading(false);
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case "auth/email-already-in-use":
          setError("Email già in uso!");
          break;
        case "auth/invalid-email":
          setError("Email non valida!");
          break;
        case "auth/operation-not-allowed":
          setError("Operazione non permessa!");
          break;
        case "auth/weak-password":
          setError("Password troppo debole!");
          break;

        default:
          setError(error.message);
          break;
      }
      console.log("an error is occurred!");
      setLoading(false);
    }
  }

  return (
    <div className="logister">
      <div className="cover">
        <Title className="sn-title" first="Secure" second="Note"></Title>
        <h2 style={{ margin: "15px 0px" }}>Welcome!</h2>
        <svg
          id="be32c85c-9388-4a2b-a4e5-271574a5b9b2"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          width="300"
          viewBox="0 0 888 651.93175"
        >
          <title>design_notes</title>
          <ellipse
            cx="96.67947"
            cy="631.79284"
            rx="96.34841"
            ry="20.00677"
            fill="#e6e6e6"
          />
          <ellipse
            cx="55.07935"
            cy="143.65068"
            rx="33.21579"
            ry="31.11353"
            fill="#2f2e41"
          />
          <path
            d="M307.78357,461.51368l8.40906,11.77269s40.36349,8.40906,25.22718,21.02265-36.99987-11.77268-36.99987-11.77268L292.64725,467.4Z"
            transform="translate(-156 -124.03413)"
            fill="#ffb8b8"
          />
          <path
            d="M264.05644,327.8096s10.93178,0,13.4545,15.13631,5.04544,65.59068,5.04544,65.59068,35.31806,46.24984,29.43172,52.13618S299.3745,477.4909,295.17,475.80909s-40.36349-51.29528-40.36349-51.29528Z"
            transform="translate(-156 -124.03413)"
            fill="#e6e6e6"
          />
          <path
            d="M230.4202,284.92338s.8409,32.79534,10.93178,34.47716,6.72725,12.61359,6.72725,12.61359-32.79534,19.34084-54.65891,0l-4.20453-10.09087s15.13632-10.93178,8.40907-26.0681Z"
            transform="translate(-156 -124.03413)"
            fill="#ffb8b8"
          />
          <path
            d="M286.76091,486.74087s49.61347,92.49968,31.11353,121.09049S271.6246,695.2856,267.42007,695.2856s-27.74991-11.77269-26.06809-19.34084,26.909-63.90887,33.63624-68.95431,3.36363-18.49994,2.52272-19.34084-28.59081-45.40894-28.59081-45.40894.84091,70.63612-2.52272,81.5679.84091,89.13606-3.36362,92.49968-23.54537,9.25-26.909,4.20453-13.4545-81.5679-6.72725-99.22693L196.784,500.19537Z"
            transform="translate(-156 -124.03413)"
            fill="#2f2e41"
          />
          <path
            d="M241.352,711.26282l21.02265,38.68168s30.27262,21.86356,9.25,25.22719-33.63625-5.04544-33.63625-5.04544l-18.49993-13.4545V717.99007Z"
            transform="translate(-156 -124.03413)"
            fill="#2f2e41"
          />
          <path
            d="M269.94279,689.39926,259.011,715.46735s-4.20453,10.93178,0,13.4545,15.97721,17.659,8.40906,19.34084a24.638,24.638,0,0,1-4.62109.45938,46.72573,46.72573,0,0,1-33.97067-13.824l-6.81718-6.81718s23.352-53.7745,23.02825-53.37579S269.94279,689.39926,269.94279,689.39926Z"
            transform="translate(-156 -124.03413)"
            fill="#2f2e41"
          />
          <circle cx="53.39754" cy="152.4802" r="28.59081" fill="#ffb8b8" />
          <path
            d="M192.99987,316.45737l8.82952,8.82951s26.909,10.09087,39.52259,0c0,0-4.625-9.67042-4.625-8.82951s36.57942,6.30679,39.943,21.4431-2.52272,41.20441-2.52272,41.20441,23.54537,110.99961,14.2954,113.52233-95.8633,23.54537-95.0224,7.56816-6.72724-81.5679-6.72724-81.5679l-29.43172-56.34072s4.20453-18.49993,14.2954-24.38628S192.99987,316.45737,192.99987,316.45737Z"
            transform="translate(-156 -124.03413)"
            fill="#e6e6e6"
          />
          <path
            d="M230.4202,480.85452l10.93178,6.72725s42.04531,28.59081,20.18175,33.63625-34.47716-20.18175-34.47716-20.18175l-8.40906-10.09087Z"
            transform="translate(-156 -124.03413)"
            fill="#ffb8b8"
          />
          <path
            d="M168.19314,357.24131l-7.56816,7.56816s-5.04543,70.63612,5.04544,84.93152,56.34071,52.97709,58.86343,50.45438,18.49994-15.13632,15.13631-16.81813-48.08542-48.41048-48.08542-48.41048l3.5174-65.11185Z"
            transform="translate(-156 -124.03413)"
            opacity="0.1"
          />
          <path
            d="M164.82951,354.7186l-7.56815,7.56815s-5.04544,70.63612,5.04544,84.93153,56.34071,52.97709,58.86343,50.45437,18.49993-15.13631,15.13631-16.81813S188.22111,432.444,188.22111,432.444l3.5174-65.11185Z"
            transform="translate(-156 -124.03413)"
            fill="#e6e6e6"
          />
          <path
            d="M221.22207,235.90286s9.71878-3.5341,22.97166,3.97587l-7.0682,1.76705s10.6023.88352,11.92759,15.01993h-4.85939s3.09234,12.36935,0,15.90345l-1.98793-3.755L236.46288,280.3l-1.32528-5.74291-2.65058.88353-1.76705-9.277s-4.41763,6.62644-7.95173,7.0682v-5.74291s-9.71877,11.04406-12.81111,10.6023l4.41762-6.18467-6.18467,1.32528,2.65057-4.41762L194.9372,273.2318l3.5341-4.41762-11.04406,3.5341-5.30115,2.20881s-7.51-17.22874,3.09233-29.15633S211.28242,230.82259,221.22207,235.90286Z"
            transform="translate(-156 -124.03413)"
            fill="#2f2e41"
          />
          <rect
            x="221.8854"
            y="68.99099"
            width="643.86161"
            height="412.35762"
            fill="#e6e6e6"
          />
          <rect
            x="240.29648"
            y="120.71092"
            width="607.03946"
            height="336.24258"
            fill="#fff"
          />
          <rect
            x="221.61048"
            y="68.62598"
            width="643.86161"
            height="27.3536"
            fill="#fbc02d"
          />
          <circle cx="241.93748" cy="82.61059" r="5.06978" fill="#fff" />
          <circle cx="261.18109" cy="82.61059" r="5.06978" fill="#fff" />
          <circle cx="280.4247" cy="82.61059" r="5.06978" fill="#fff" />
          <rect
            x="295.45433"
            y="155.59882"
            width="155.98054"
            height="266.46676"
            fill="#e6e6e6"
          />
          <rect
            x="478.36009"
            y="155.59882"
            width="129.98379"
            height="73.34799"
            fill="#fbc02d"
          />
          <rect
            x="478.36009"
            y="249.37284"
            width="129.98379"
            height="78.91873"
            fill="#e6e6e6"
          />
          <rect
            x="478.36009"
            y="348.71759"
            width="129.98379"
            height="73.34799"
            fill="#e6e6e6"
          />
          <rect
            x="636.19754"
            y="155.59882"
            width="155.98054"
            height="116.12476"
            fill="#e6e6e6"
          />
          <rect
            x="636.19754"
            y="305.94083"
            width="155.98054"
            height="116.12476"
            fill="#fbc02d"
          />
          <polygon
            points="544.691 188.755 542.655 188.214 587.089 21.06 844.827 21.06 844.827 23.166 588.708 23.166 544.691 188.755"
            fill="#3f3d56"
          />
          <path
            d="M1021.88725,168.25962A22.11275,22.11275,0,1,1,1044,146.14687,22.13792,22.13792,0,0,1,1021.88725,168.25962Zm0-42.11952a20.00678,20.00678,0,1,0,20.00677,20.00677A20.02953,20.02953,0,0,0,1021.88725,126.1401Z"
            transform="translate(-156 -124.03413)"
            fill="#3f3d56"
          />
          <circle cx="865.88725" cy="22.11275" r="8.4239" fill="#fbc02d" />
          <polygon
            points="672.947 530.706 415.208 530.706 415.208 528.6 671.328 528.6 715.345 363.01 717.381 363.551 672.947 530.706"
            fill="#3f3d56"
          />
          <path
            d="M550.1486,675.79987a22.11275,22.11275,0,1,1,22.11275-22.11275A22.13792,22.13792,0,0,1,550.1486,675.79987Zm0-42.11952a20.00678,20.00678,0,1,0,20.00677,20.00677A20.02953,20.02953,0,0,0,550.1486,633.68035Z"
            transform="translate(-156 -124.03413)"
            fill="#3f3d56"
          />
          <circle cx="394.1486" cy="529.653" r="8.4239" fill="#fbc02d" />
        </svg>
      </div>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div style={{ margin: "0 0 16px 0" }}>
            <h1 style={{ fontSize: "45px" }}>Signup</h1>
            <p style={{ fontSize: "18px" }}>Inscriviti, è gratis</p>
          </div>

          <FormControl fullWidth>
            {error !== "" && <Alert severity="error">{error}</Alert>}
            {info !== "" && <Alert severity="success">{info}</Alert>}
            <TextField
              margin="normal"
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              type="email"
              value={formValues.email}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              id="password"
              label="Password"
              type="password"
              value={formValues.password}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              id="passwordConfirmation"
              label="Conferma Password"
              type="password"
              value={formValues.passwordConfirmation}
              onChange={handleInputChange}
            />
            <p style={{ margin: "5px 0 10px 0" }}>
              Sei gia inscritto? <Link to="/login">Accedi ora!</Link>
            </p>
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Inscriviti
            </Button>
          </FormControl>
        </form>
      </div>
    </div>
  );
}
