import React, { useState, useContext, useEffect } from "react";
import { auth, db, googleProvider, facebookProvider } from "../firebase_config";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [loading, setLoading] = useState(true);

  const [currentUser, setCurrentUser] = useState();
  const [userSettings, setUserSettings] = useState();

  async function signup(email, password) {
    await auth.createUserWithEmailAndPassword(email, password);
    return;
  }

  async function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  async function SignInWithGoogle() {
    const provide = googleProvider;
    return auth.signInWithPopup(provide);
  }

  async function SignInWithFacebook() {
    const provide = facebookProvider;
    return auth.signInWithPopup(provide);
  }

  async function logout() {
    return auth.signOut();
  }

  async function changePassword(password) {
    return auth.currentUser.updatePassword(password);
  }

  async function uploadAvatar(url) {
    return auth.currentUser.updateProfile({ photoURL: url });
  }

  async function updateDisplayName(value) {
    return auth.currentUser.updateProfile({ displayName: value });
  }

  async function updateEmail(email) {
    await auth.currentUser.verifyBeforeUpdateEmail(email);
    return logout();
  }

  async function updatePassword(password) {
    await auth.currentUser.updatePassword(password);
  }

  async function changeSetting(key, value) {
    db.collection("utenti")
      .doc(currentUser.email)
      .set({ [key]: value });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("utente non connesso");
      if (user) {
        console.log("utente connesso");
        db.collection("utenti")
          .doc(user.email)
          .onSnapshot((snapshot) => {
            setUserSettings(snapshot.data());
          });
      }
      setCurrentUser(user);

      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userSettings,
    changeSetting,
    signup,
    login,
    SignInWithGoogle,
    SignInWithFacebook,
    logout,
    changePassword,
    uploadAvatar,
    updateDisplayName,
    updateEmail,
    updatePassword,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
