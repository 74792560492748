import React from "react";
import { Redirect, Route } from "react-router";
import { useAuth } from "./Contexts/AuthContext";

export default function PublicRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (currentUser === null) return <Component {...props} />;
        else return <Redirect to="/" />;
      }}
    ></Route>
  );
}
