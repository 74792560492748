import React, { useState } from "react";
import "./UserDetails.css";

import Title from "../../Components/title";
import EditableText from "../../Components/EditableText";
import {
  ChangeEmailDialog,
  ChangePasswordModal,
} from "../../Components/Modals";
//material
import {
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Button,
  ButtonGroup,
  Paper,
} from "@material-ui/core";
import { ArrowBack, Close } from "@material-ui/icons";

import { storage } from "../../firebase_config";
import { useAuth } from "../../Contexts/AuthContext";
import { useHistory } from "react-router";

export default function UserDetails() {
  const { currentUser, logout, uploadAvatar, updateDisplayName } = useAuth();
  const [profile, setProfile] = useState({
    displayName: currentUser.displayName,
    email: currentUser.email,
    photoURL: currentUser.photoURL,
    photoLoading: false,
  });

  const history = useHistory();

  function backHandler() {
    history.goBack();
  }

  async function logoutHandler() {
    await logout();
    history.push("/login");
  }

  async function deletePhoto() {
    setProfile({ ...profile, photoLoading: true });
    await uploadAvatar(null);
    setProfile({ ...profile, photoLoading: false, photoURL: null });
  }
  async function uploadHandler(event) {
    console.log(event);
    if (event.target.files.length !== 0) {
      try {
        console.log("modifico la profile pic");
        if (event.target.files[0].size >= 5000000)
          throw new Error("Dimensione file massima: 5MB");
        setProfile({ ...profile, photoLoading: true });
        var metadata = {
          name: currentUser.uid + ".jpg",
        };
        const storageRef = storage.ref("profile/" + currentUser.uid);
        storageRef
          .put(event.target.files[0], metadata)
          .then(async (snapshot) => {
            const link = await storageRef.getDownloadURL();
            await uploadAvatar(link);
            setProfile({ ...profile, photoLoading: false, photoURL: link });
          });
      } catch (error) {
        alert(error);
      }
    }
  }

  function changeDisplayName(name) {
    updateDisplayName(name);
    setProfile({ ...profile, displayName: name });
  }

  return (
    <div className="user-details">
      <AppBar position="relative" className="header">
        <Toolbar disableGutters className="toolbar">
          <IconButton
            onClick={backHandler}
            children={<ArrowBack className="icon" />}
          ></IconButton>
          <Title first="User" second="Note" />
          <div></div>
        </Toolbar>
      </AppBar>
      <div className="body">
        <Paper variant="elevation" elevation={6} className="card">
          <div className="username">
            <label htmlFor="profileimg">
              {profile.photoLoading ? (
                <CircularProgress color="secondary" />
              ) : (
                <Avatar src={profile.photoURL} className="avatar" />
              )}
              {profile.photoURL == null ? (
                <h6>Inserisci una foto</h6>
              ) : (
                <IconButton
                  className="close-overlay"
                  aria-label="remove"
                  onClick={deletePhoto}
                >
                  <Close className="icon"></Close>
                </IconButton>
              )}
            </label>
            <input
              type="file"
              id="profileimg"
              accept="image/*"
              onChange={uploadHandler}
            />

            <div className="h1eh2">
              <EditableText onDone={changeDisplayName}>
                {!currentUser.displayName ? (
                  <h1>Utente</h1>
                ) : (
                  <h1>{profile.displayName}</h1>
                )}
              </EditableText>

              <h4>{profile.email}</h4>
            </div>
          </div>
          <ButtonGroup
            style={{ marginTop: "20px" }}
            orientation="vertical"
            variant="contained"
            color="primary"
            fullWidth
            aria-label=""
          >
            <ChangeEmailDialog>Cambiare email</ChangeEmailDialog>
            <ChangePasswordModal>Cambiare Password</ChangePasswordModal>
          </ButtonGroup>
          <Button
            fullWidth
            onClick={logoutHandler}
            className="logout-btn"
            variant="contained"
          >
            Logout
          </Button>
        </Paper>
      </div>
    </div>
  );
}
