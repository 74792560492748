import firebase from "@firebase/app";
import "@firebase/firestore";
import "@firebase/auth";
import "@firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyAJ2L8bX5gx7ifPFnB5UApU44kM3ryDn0U",
  authDomain: "note-1a23f.firebaseapp.com",
  databaseURL: "https://note-1a23f.firebaseio.com",
  projectId: "note-1a23f",
  storageBucket: "note-1a23f.appspot.com",
  messagingSenderId: "753911520506",
  appId: "1:753911520506:web:76c721afeebdf46e19bd43",
  measurementId: "G-C37HFKEEDB",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();

export { db, auth, storage, googleProvider, facebookProvider };
