import "./App.css";
import React from "react";

//import material
//import component
import { CustomThemeProvider } from "./Contexts/ThemeContext";
import Register from "./Screens/Register/Register";
import Login from "./Screens/Login/Login";
import { AuthProvider } from "./Contexts/AuthContext";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Dashboard from "./Screens/Dashboard/Dashboard";
import UserDetails from "./Screens/UserDetails/UserDetails";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <AuthProvider>
            <CustomThemeProvider>
              <PrivateRoute exact path="/" component={Dashboard}></PrivateRoute>
              <PrivateRoute
                path="/user-details"
                component={UserDetails}
              ></PrivateRoute>
              <PublicRoute path="/signup" component={Register}></PublicRoute>
              <PublicRoute path="/login" component={Login}></PublicRoute>
            </CustomThemeProvider>
          </AuthProvider>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
