import "./Login.css";
import noteimg from "../../media/img/undraw_design_notes_8dmv.svg";
import Title from "../../Components/title";
import { useAuth } from "../../Contexts/AuthContext";
import React, { useState } from "react";
import { FormControl, TextField, Button, IconButton } from "@mui/material";
import { Alert } from "@mui/lab";
import { Google, Facebook } from "@mui/icons-material";
import { Link, useHistory } from "react-router-dom";

export default function Login() {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { login, SignInWithGoogle, SignInWithFacebook } = useAuth();
  const history = useHistory();

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({
      ...formValues,
      [id]: value,
    });
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (formValues.email === "" || formValues.password === "") {
      return setError("Nessun campo può essere vuoto");
    }
    try {
      setError("");
      setLoading(true);
      console.log("trying login with: ", formValues.email, formValues.password);
      await login(formValues.email, formValues.password);
      setLoading(false);
      history.push("/");
    } catch (error) {
      console.log(error);
      switch (error.code) {
        case "auth/invalid-email":
          setError("L'email utilizzata non è valida");
          break;
        case "auth/user-disabled":
          setError("Utente disabilitato!");
          break;
        case "auth/user-not-found":
          setError("Utente non trovato");
          break;
        case "auth/wrong-password":
          setError("Password errata!");
          break;

        default:
          setError(error.message);
          break;
      }
      console.log("an error is occurred!");
      setLoading(false);
    }
  }

  async function googleLogin() {
    setLoading(true);
    await SignInWithGoogle();
    setLoading(false);
  }
  async function facebookLogin() {
    setLoading(true);
    await SignInWithFacebook();
    setLoading(false);
  }

  return (
    <div className="logister">
      <div className="cover">
        <Title className="sn-title" first="Secure" second="Note"></Title>
        <h2 style={{ margin: "15px 0px" }}>Welcome!</h2>
        <img src={noteimg} alt="" srcSet="" />
      </div>
      <div className="form">
        <form onSubmit={handleSubmit}>
          <div style={{ margin: "0 0 16px 0" }}>
            <h1 style={{ fontSize: "45px" }}>Login</h1>
            <p style={{ fontSize: "18px" }}>Bentornato</p>
          </div>

          <FormControl fullWidth>
            {error !== "" && <Alert severity="error">{error}</Alert>}
            <TextField
              margin="normal"
              variant="outlined"
              fullWidth
              id="email"
              label="Email"
              type="email"
              value={formValues.email}
              onChange={handleInputChange}
            />
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              id="password"
              label="Password"
              type="password"
              value={formValues.password}
              onChange={handleInputChange}
            />
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              type="submit"
            >
              Accedi
            </Button>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ margin: "5px 0 10px 0", textAlign: "left" }}>
                <Link to="/reset-password">Password dimenticata?</Link>
              </p>
              <p style={{ margin: "5px 0 10px 0", textAlign: "right" }}>
                Nuovo su SecureNote? <Link to="/signup">Iscriviti ora!</Link>
              </p>
            </div>
          </FormControl>
        </form>
        <div className="socials">
          <IconButton
            className="icon-button"
            aria-label=""
            onClick={googleLogin}
          >
            <Google className="icon"></Google>
          </IconButton>
          <IconButton
            className="icon-button"
            aria-label=""
            onClick={facebookLogin}
          >
            <Facebook className="icon"></Facebook>
          </IconButton>
        </div>
      </div>
    </div>
  );
}
