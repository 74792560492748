import React from "react";
import { RichUtils } from "draft-js";
import { Button, ButtonGroup } from "@material-ui/core";

import { FormatBold, FormatUnderlined, FormatItalic } from "@material-ui/icons";

export default function Inline({ editorState, onEditorStateChange }) {
  function toggleInlineStyle(e, style) {
    e.preventDefault();
    onEditorStateChange(RichUtils.toggleInlineStyle(editorState, style));
  }
  function getInlineStyle(style) {
    return editorState.getCurrentInlineStyle().has(style);
  }

  const InlineStyles = [
    { label: "B", value: "BOLD", icon: <FormatBold /> },
    { label: "I", value: "ITALIC", icon: <FormatItalic /> },
    { label: "U", value: "UNDERLINE", icon: <FormatUnderlined /> },
  ];

  return (
    <ButtonGroup color="primary" aria-label="" style={{ marginRight: "5px" }}>
      {InlineStyles.map((element) => {
        const { label, value, icon } = element;
        return (
          <Button
            key={"inline-" + label}
            onMouseDown={(event) => {
              toggleInlineStyle(event, value);
            }}
            variant={getInlineStyle(value) ? "outlined" : "contained"}
          >
            {icon}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
