import React, { useState } from "react";

import { TextField } from "@material-ui/core";

export default function EditableText({ children, onDone }) {
  const [editable, setEditable] = useState(false);
  const [text, setText] = useState(children.props.children);

  console.log();

  function onChange(value) {
    setText(value.target.value);
  }
  return (
    <>
      {editable ? (
        <TextField
          //          fullWidth
          autoFocus
          onKeyDown={(p) => {
            if (p.key === "Enter") {
              console.log(p);
              onDone(p.target.value);
              setEditable(false);
            }
          }}
          onBlur={(b) => {
            onDone(b.target.value);
            setEditable(false);
          }}
          defaultValue={text}
          onChange={onChange}
        />
      ) : (
        <div onClick={() => setEditable(true)}>{children}</div>
      )}
    </>
  );
}
