import React, { useState } from "react";
import Title from "./title";
//Material
import {
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Switch,
  AppBar,
  Toolbar,
} from "@material-ui/core";

import {
  Add,
  AccountCircle,
  ExitToApp,
  BrightnessHigh,
  Brightness2,
} from "@material-ui/icons";
import { useAuth } from "../Contexts/AuthContext";
import { useTheme } from "../Contexts/ThemeContext";
import { useHistory } from "react-router";
//auth

const Header = ({ newNote }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const { switchTheme, isDark } = useTheme();

  function handleNewNote() {
    newNote();
  }
  const { currentUser, logout } = useAuth();

  return (
    <div>
      <AppBar position="relative" className="header">
        <Toolbar disableGutters className="toolbar">
          <IconButton
            children={<Add className="icon" />}
            onClick={handleNewNote}
          ></IconButton>
          <Title first="Secure" second="Note" />

          <Avatar
            onClick={(event) => setAnchorEl(event.target)}
            style={{ margin: "10px" }}
            variant="rounded"
            src={currentUser.photoURL}
          />
          <Menu
            getContentAnchorEl={null}
            anchorOrigin={{
              horizontal: "left",
              vertical: "bottom",
            }}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <ListItemText
              style={{ padding: "0px 15px" }}
              primary={currentUser.displayName}
              secondary={currentUser.email}
            ></ListItemText>

            <Divider></Divider>

            <MenuItem onClick={() => history.push("/user-details")}>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <ListItemText primary="Profilo"></ListItemText>
            </MenuItem>
            <MenuItem>
              <ListItemIcon>
                {!isDark() ? <Brightness2 /> : <BrightnessHigh />}
              </ListItemIcon>

              <Switch
                value="darkMode"
                checked={isDark()}
                onChange={(value) => {
                  switchTheme(!isDark());
                }}
                inputProps={{ "aria-label": "" }}
              />
            </MenuItem>

            <MenuItem style={{ color: "red" }} onClick={() => logout()}>
              <ListItemIcon>
                <ExitToApp style={{ color: "red" }} />
              </ListItemIcon>
              <ListItemText primary="Logout"></ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
