/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ChooseColorNote, ConfirmExitDialogButton } from "./Modals";

//editor
import draftToHtml from "draftjs-to-html";
import { convertToRaw, ContentState, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import CustomEditor from "./Editor/CustomEditor";

//firebase
import { db } from "../firebase_config";
import { useAuth } from "../Contexts/AuthContext";

//material
import {
  AppBar,
  Dialog,
  Slide,
  IconButton,
  Toolbar,
  TextField,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import Title from "./title";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ id, open, setOpen }) {
  const [note, setNote] = useState({
    id: null,
    title: "",
    locked: false,
    body: "",
    color: "#000",
  });
  const [edited, setEdited] = useState(false);
  const [editorState, setEditorState] = useState(getEditorState(""));
  const { currentUser } = useAuth();

  useEffect(() => {
    if (open) {
      if (id !== null) {
        console.log("fetching del dialog con id:", id);
        fetchNote(id);
      } else {
        setNote({
          id: null,
          title: "",
          locked: false,
          body: "",
          color: "#000",
        });
        setEditorState(getEditorState(""));
      }
    }
  }, [open]);

  async function fetchNote(i) {
    console.log("Fetching.. for id", i);
    db.collection("utenti")
      .doc(currentUser.email)
      .collection("note")
      .doc(i)
      .get()
      .then((querySnapshot) => {
        setNote({
          id: querySnapshot.id,
          title: querySnapshot.data().title,
          body: querySnapshot.data().body,
          locked: querySnapshot.data().locked,
          color: querySnapshot.data().color,
        });
        setEditorState(getEditorState(querySnapshot.data().body));
        //console.log(this.state.note);
      });
  }

  async function handleSave() {
    await saveNote();
    setOpen(false);
  }

  async function saveNote() {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);

    if (id !== null) {
      db.collection("utenti")
        .doc(currentUser.email)
        .collection("note")
        .doc(id)
        .set({
          title: note.title,
          body: markup,
          locked: note.locked,
          color: note.color,
        });
    } else {
      db.collection("utenti")
        .doc(currentUser.email)
        .collection("note")
        .doc()
        .set({
          title: note.title,
          body: markup,
          locked: note.locked,
          color: note.color,
        });
    }
  }

  function getEditorState(body) {
    let eState;
    const contentBlock = htmlToDraft(body);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      eState = EditorState.createWithContent(contentState);
    }
    return eState;
  }

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar
        style={{
          backgroundColor: note.color !== "#000" ? note.color : "#fbc02d",
        }}
        position="relative"
        className="header"
      >
        <Toolbar disableGutters className="toolbar">
          <ConfirmExitDialogButton
            edited={edited}
            setDialogOpen={setOpen}
          ></ConfirmExitDialogButton>
          <Title first="Editor" second="Note" />
          <div>
            <ChooseColorNote note={note} setNote={setNote}></ChooseColorNote>
            <IconButton onClick={handleSave}>
              <Check className="icon" />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <div className="noteDetail">
        <TextField
          fullWidth
          size="medium"
          className="titleField"
          id="title"
          label="Titolo"
          value={note.title}
          onChange={(event) => {
            setEdited(true);
            console.log("title edited");
            setNote({ ...note, title: event.target.value });
          }}
        />
        <CustomEditor
          editorState={editorState}
          onEditorStateChange={(value) => {
            setEdited(true);
            console.log("body edited");
            setEditorState(value);
          }}
        ></CustomEditor>
      </div>
    </Dialog>
  );
}
