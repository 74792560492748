import React, { useState, useContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useAuth } from "./AuthContext";

const lightTheme = createTheme({
  palette: {
    type: "light",
    background: {
      //paper: "#d2d2d2",
    },
    primary: {
      main: "#fbc02d",
    },
    secondary: {
      main: "#ff5722",
    },
  },
});
const darktheme = createTheme({
  palette: {
    type: "dark",
    background: {},
    primary: {
      main: "#fbc02d",
    },
    secondary: {
      main: "#ff5722",
    },
  },
});

const CustomThemeContext = React.createContext();

export function useTheme() {
  return useContext(CustomThemeContext);
}

export function CustomThemeProvider({ children }) {
  const { changeSetting } = useAuth();
  const [theme, setTheme] = useState(lightTheme);

  const isDark = () => {
    return theme.palette.type === "dark";
  };

  const switchTheme = (isDark) => {
    isDark ? setTheme(darktheme) : setTheme(lightTheme);
    changeSetting("darkMode", isDark);
  };

  const value = {
    theme,
    isDark,
    switchTheme,
  };
  return (
    <CustomThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}> {children}</ThemeProvider>
    </CustomThemeContext.Provider>
  );
}
