import React from "react";
import { Editor, RichUtils } from "draft-js";
import { Paper } from "@material-ui/core";
import Inline from "./Inline";
import Block from "./Block";

function CustomEditor(props) {
  const { editorState, onEditorStateChange } = props;

  function handleKeyCommand(command) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) onEditorStateChange(newState);
  }

  return (
    <div className="editor">
      <Paper
        elevation={3}
        style={{ padding: "10px", marginTop: "5px", display: "flex" }}
      >
        <Inline
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        ></Inline>
        <Block
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
        ></Block>
      </Paper>
      <Editor
        editorState={editorState}
        onChange={onEditorStateChange}
        handleKeyCommand={handleKeyCommand}
        placeholder="Inizia con un testo.."
      ></Editor>
    </div>
  );
}

export default CustomEditor;
